import axios from 'axios';
import storage from './storage';
import config from './config';

var baseURL = process.env.REACT_APP_API_URL;


class Request {
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      withCredentials: true,
    });
    this.request_instances = [];
  }

  createHttpRequest(method, url, data = null, single = true) {
    const methodToLowerCase = method.toLowerCase();
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    if (methodToLowerCase in axios && typeof axios[methodToLowerCase] === 'function') {
      this.axiosInstance = axios.create({
        baseURL: baseURL,
        withCredentials: true,
        cancelToken: cancelToken
      });
      if(single){
        const path = url.includes("?") ? url.substr(0, url.indexOf("?")) : url;
        if(typeof this.request_instances[path] != "undefined"){
            this.request_instances[path].cancel.cancel({message: "cancelled"})
        }
        this.request_instances[path] = {
          fn: this.axiosInstance.bind(window, { method, url, baseURL, data, }),
          cancel: source
        };
        return this.request_instances[path].fn;
      }else{
        return this.axiosInstance.bind(window, { method, url, baseURL, data, })
      }
      
      
    } else {
      return () => { new Promise((resolve) => resolve({ data })); };
    }
  } 

  get(url, single = true) {
    const httpRequest = this.createHttpRequest('GET', url, null, single);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
      // await new Promise((resolve) => {
      //   setTimeout(resolve, 1200);
      // });

      return httpResponse;
    }).catch((error, response) => {
      //console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return {cancel: true};
    });
  }

  post(url, data, single = false) {
    const httpRequest = this.createHttpRequest('POST', url, data, single);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    }).catch((error, response) => {
      //console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return {cancel: true};
    });
  }


  patch(url, data) {
    const httpRequest = this.createHttpRequest('PATCH', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    }).catch((error, response) => {
      //console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return {cancel: true};
    });
  }


  // patch alias
  update(url, data) {
    const httpRequest = this.createHttpRequest('PATCH', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    }).catch((error, response) => {
      //console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return {cancel: true};
    });
  }


  delete(url, data) {
    const httpRequest = this.createHttpRequest('DELETE', url, data);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
    // await new Promise((resolve) => {
    //   setTimeout(resolve, 1200);
    // });

    return httpResponse;
    }).catch((error, response) => {
      //console.log(`%cHTTP GET Request error\r\n${baseURL + url}\r\n%c${error}`, 'background-color: #FFF; color: #FF0000; font-size: 18px; border: 2px solid #FF0000; padding: 8px;', 'background-color: #FFF; color: #333; font-size: 15px;');

      return {cancel: true};
    });
  }

  response_valid(r){
    if(typeof r == 'undefined' || r.cancel){
      return false;
    }else{
      return true;
    }
  }

  get_user_status(url = `api/status`) {
    const httpRequest = this.createHttpRequest('GET', url, null, false);

    return httpRequest().then(async (httpResponse) => {
      // write here just before response is processed
      // await new Promise((resolve) => {
      //   setTimeout(resolve, 1200);
      // });
      if(httpResponse.data.login != true) {
        storage.me = {};
      }
      return httpResponse.data.login == true;
    }).catch((error, response) => {
     
      return error;
    });
  }

  get_company_data() {
    request.get(`api/company`).then((response) => {
      storage.set('company', {
        ...response.data,
        active_announcement: response.data.active_announcement != '0'
      });
      config.eshop_title = response.data.eshop_name ?? 'Present Team';
      window.i18n.refreshTitles();
    });
  }

  cancelabePromise = promise => {
    let hasCanceled = false;
    const wrappedPromise = new Promise((resolve, reject) => {
      promise
        .then(val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)))
        .catch(
          error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled = true;
      }
    }
  };
}

const request = new Request();
window.request = request;

export default request;